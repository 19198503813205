.root {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95%;
}

.main-text-container {
    display: flex;
    align-items: center;
}
